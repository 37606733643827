import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import Container from './Container';

const ADVISER_DATA = [
  {
    id: 'adviser-1',
    title: '设定业务目标',
    content: '结合实际业务需求，明确目标以及优先级',
  },
  {
    id: 'adviser-2',
    title: '明确应用场景',
    content: '确认标签的使用场景，尽可能具象化',
  },
  {
    id: 'adviser-3',
    title: '确认标签范围',
    content: '根据场景讨论标签体系的范围，根据数据源的有无确认可行性',
  },
  {
    id: 'adviser-4',
    title: '明确关键指标',
    content: '结合具体的业务指标，设定需要计算阀值得标签',
  },
  {
    id: 'adviser-5',
    title: '数据洞察确认逻辑',
    content: '基于具体的数据库现状进行分析与逻辑确认',
  },
  {
    id: 'adviser-6',
    title: '完善标签值',
    content: '与业务不断讨论完善标签的各个值定义',
  },
];

function AdviserBox({ id, title, content }) {
  return (
    <Col lg={4} md={8} className="adviser-box" key={id}>
      <div className="adviser-box-inner">
        <div className="arrow" />
        <div className="adviser-box-title">{title}</div>
        <div className="adviser-box-content">{content}</div>
      </div>
    </Col>
  );
}

AdviserBox.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
};

function TagsAdviser() {
  return (
    <section className="tags-adviser">
      <Container>
        <div className="title-wrapper">
          <h3>标签体系顾问服务</h3>
          <h4>为您提供令人震撼的标签体系建设平台，也为您提供专业的标签体系顾问服务</h4>
        </div>
        <Row className="adviser-boxes" gutter={30}>
          {ADVISER_DATA.map((item) => (
            <AdviserBox {...item} key={item.id} />
          ))}
        </Row>
        <h4 className="bottom-content">
          Convertlab顾问服务团队为零售、快消、B2B、房产、金融等行业的TOP级企业成功实施过完全定制化的标签体系方案，拥有丰富的实战经验和口碑，帮助您快速提升标签管理和应用水平，在数字化和营销能力上进行降维打击。
        </h4>
      </Container>
    </section>
  );
}

export default TagsAdviser;
