import React from 'react';
import PropTypes from 'prop-types';

const TabTitle = ({ title, description, onMouseEnter, active }) => {
  return (
    <div className={`tab-title ${active ? 'active' : ''}`}>
      <h4 onMouseEnter={onMouseEnter}>{title}</h4>
      {description && <p>{description}</p>}
    </div>
  );
};

TabTitle.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onMouseEnter: PropTypes.func,
  active: PropTypes.bool,
};

export default TabTitle;
