import React, { useState } from 'react';
import { Tabs, Button, Row, Col } from 'antd';
import Container from './Container';
import TabTitle from './TabTitle';

const TABS_DATA = [
  {
    tabTitle: '智能化商品推荐',
    bgId: '1',
  },
  {
    tabTitle: '完善客户画像',
    bgId: '2',
  },
  {
    tabTitle: '赋能全生命周期管理',
    bgId: '3',
  },
];

const PRODUCT_DATA = [
  {
    id: '1',
    title: '机器学习',
    iconId: 'icontags-1',
    contentList: [
      { id: '1-1', content: '特征提取建模' },
      { id: '1-2', content: '特征选择建模' },
      { id: '1-3', content: '预测优化模型' },
      { id: '1-4', content: 'EM' },
      { id: '1-5', content: 'Bagging' },
      { id: '1-6', content: 'AdaBoost' },
    ],
  },
  {
    id: '2',
    title: '预测算法',
    iconId: 'icontags-2',
    contentList: [
      { id: '2-1', content: '分层贝叶斯模型' },
      { id: '2-2', content: 'SVR' },
      { id: '2-3', content: '定价模型' },
      { id: '2-4', content: '动态模型' },
      { id: '2-5', content: '随机森林' },
      { id: '2-6', content: '流失预警模型' },
      { id: '2-7', content: 'RFM模型' },
      { id: '2-8', content: '产品扩散模型' },
      { id: '2-9', content: '逻辑回归' },
    ],
  },
  {
    id: '3',
    title: '推荐算法',
    iconId: 'icontags-3',
    contentList: [
      { id: '3-1', content: 'Wide&Deep' },
      { id: '3-2', content: 'DeepFFM' },
      { id: '3-3', content: 'Apriori' },
      { id: '3-4', content: 'FPTree' },
      { id: '3-5', content: 'Hybrid CF' },
      { id: '3-6', content: 'Content-based' },
      { id: '3-7', content: 'NBI二分图' },
      { id: '3-8', content: 'Heat Diffusion' },
      { id: '3-9', content: 'SVD矩阵分解' },
    ],
  },
];

function ProductRecommendation() {
  return (
    <div className="product-recommendation">
      <h4 className="title">根据客户标签进行聚类，让客户愉快地购买你的商品或服务</h4>
      <Row className="opacity-boxes-wrapper" gutter={30}>
        {PRODUCT_DATA.map((productItem) => (
          <Col span={8} key={productItem.id}>
            <div className="opacity-box">
              <div className={`iconfont font-40 ${productItem.iconId}`} />
              <div className="title">{productItem.title}</div>
              <div className="content-list-wrapper">
                {(productItem.contentList || []).map((contentItem) => (
                  <div className="content-item" key={contentItem.id}>
                    {contentItem.content}
                  </div>
                ))}
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

const PERSONA_DATA = [
  {
    id: '1',
    title: '客户标签',
    iconId: 'icontags-4',
    contentList: [
      {
        id: '1-1',
        subTitle: '人口',
        subList: [
          { id: '1-1-1', content: '性别' },
          { id: '1-1-2', content: '年龄范围' },
          { id: '1-1-3', content: '职业' },
          { id: '1-1-4', content: '兴趣' },
        ],
      },
      {
        id: '1-2',
        subTitle: '归属',
        subList: [
          { id: '1-2-1', content: '省份' },
          { id: '1-2-2', content: '城市' },
          { id: '1-2-3', content: '区域' },
          { id: '1-2-4', content: '业务部门' },
        ],
      },
    ],
  },
  {
    id: '2',
    title: '内容标签',
    iconId: 'icontags-5',
    contentList: [
      {
        id: '2-1',
        subTitle: '阶段状态',
        subList: [
          { id: '2-1-1', content: '潜客' },
          { id: '2-1-2', content: '会员' },
          { id: '2-1-3', content: '新客' },
          { id: '2-1-4', content: '老客' },
          { id: '2-1-5', content: '沉睡' },
          { id: '2-1-6', content: '流失' },
        ],
      },
      {
        id: '2-2',
        subTitle: '沟通互动',
        subList: [
          { id: '2-2-1', content: 'Social' },
          { id: '2-2-2', content: '签到' },
          { id: '2-2-3', content: '菜单点击' },
          { id: '2-2-4', content: '活动报名' },
          { id: '2-2-5', content: '分享裂变' },
        ],
      },
    ],
  },
  {
    id: '3',
    title: '商品标签',
    iconId: 'icontags-6',
    contentList: [
      {
        id: '3-1',
        subTitle: '消费购买',
        subList: [
          { id: '3-1-1', content: '购买频次' },
          { id: '3-1-2', content: '购买品类' },
          { id: '3-1-3', content: '价格敏感度' },
          { id: '3-1-4', content: '购买间隔' },
          { id: '3-1-5', content: '购买金额' },
        ],
      },
      {
        id: '3-2',
        subTitle: '价值水平',
        subList: [
          { id: '3-2-1', content: '消费潜力' },
          { id: '3-2-2', content: '消费能力' },
          { id: '3-2-3', content: '影响力价值' },
          { id: '3-2-4', content: '传播价值' },
          { id: '3-2-5', content: '活跃度价值' },
        ],
      },
    ],
  },
  {
    id: '4',
    title: '关键对象标签',
    iconId: 'icontags-7',
    contentList: [
      {
        id: '4-1',
        subTitle: '内容偏好',
        subList: [
          { id: '4-1-1', content: '活动偏好' },
          { id: '4-1-2', content: '权益偏好' },
          { id: '4-1-3', content: '类型偏好' },
          { id: '4-1-4', content: '市场偏好' },
        ],
      },
      {
        id: '4-2',
        subTitle: '购买偏好',
        subList: [
          { id: '4-2-1', content: '品牌偏好' },
          { id: '4-2-2', content: '品类偏好' },
          { id: '4-2-3', content: '渠道偏好' },
          { id: '4-2-4', content: '促销偏好' },
          { id: '4-2-5', content: '时间偏好' },
        ],
      },
    ],
  },
];

function Persona() {
  return (
    <div className="persona">
      <div className="title-wrapper">
        <h4 className="title">根据标签体系沉淀客户资产，第一次前所未有地看清楚你的客户</h4>
        <p className="description">
          通过标签体系的建设和沉淀不断完善用户画像，全面了解客户的兴趣和偏好，支持战略性营销决策和企业发展。
        </p>
        <Button ghost className="get-detail">
          <a href="/personas/">了解用户画像</a>
        </Button>
      </div>
      <Row className="opacity-boxes-wrapper" gutter={30}>
        {PERSONA_DATA.map((personaItem) => (
          <Col span={6} key={personaItem.id}>
            <div className="opacity-box">
              <div className={`iconfont font-40 ${personaItem.iconId}`} />
              <div className="title">{personaItem.title}</div>
              <div className="content-list-wrapper" gutter={5}>
                <Row gutter={5}>
                  {(personaItem.contentList || []).map((list) => (
                    <Col span={12} key={list.id}>
                      <div className="content-list">
                        <div className="sub-title">{list.subTitle}</div>
                        <div>
                          {(list.subList || []).map((item) => (
                            <div className="list-item" key={item.id}>
                              {item.content}
                            </div>
                          ))}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

const CYCLE_DATA = [
  {
    title: '人群包投放',
    id: '1',
    iconId: 'icontags-8',
    iconSize: 40,
    content: '品牌曝光',
  },
  {
    title: '来源标记',
    id: '2',
    iconId: 'icontags-9',
    iconSize: 34,
    content: '客户留资',
  },
  {
    title: '导购赋能',
    id: '3',
    iconId: 'icontags-10',
    iconSize: 34,
    content: '会员招募',
  },
  {
    title: '记录偏好',
    id: '4',
    iconId: 'icontags-11',
    iconSize: 30,
    content: '新客首购',
  },
  {
    title: '阶段圈人',
    id: '5',
    iconId: 'icontags-12',
    iconSize: 37,
    content: '会员关怀',
  },
  {
    title: '流程优化',
    id: '6',
    iconId: 'icontags-14',
    iconSize: 40,
    content: '活动邀约',
  },
  {
    title: '购买预测',
    id: '7',
    iconId: 'icontags-13',
    iconSize: 30,
    content: '会员复购',
  },
];

const Arrow = () => {
  return (
    <div className="arrow-wrapper">
      <div className="line" />
      <div className="point" />
    </div>
  );
};

function LifeCycle() {
  return (
    <div className="life-cycle">
      <div>
        <h4 className="title">让你第一次拥有全渠道营销和全场景会员营销能力</h4>
        <p className="description">
          通过对客户生命周期、客户商品偏好、购买偏好、沟通偏好和购买预测的拆解和标记，形成颗粒度精细的标签体系，利用算法架构标签来建立深度用户洞察和客户分层。
        </p>
        <p className="description">
          无论客户在什么地方，不管是线下还是线上，你都可以快速准确的与客户进行精准的沟通。即便你面对的是数以亿计的客户群体，我们的营销自动化流程通过精准的标签体系，也能让瞬息间发生的亿万次营销触达，犹如面对面沟通一样精准而亲切。
        </p>
      </div>
      <div className="dashed-box-wrapper">
        {CYCLE_DATA.map((cycleItem) => (
          <div key={cycleItem.id} className="cycle-item">
            <Arrow />
            <div className="icon-and-text">
              <div className={`iconfont font-${cycleItem.iconSize} ${cycleItem.iconId}`} />
              <div className="content">{cycleItem.content}</div>
            </div>
            <div className="title">{cycleItem.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

const RENDER_MAP = {
  1: <ProductRecommendation />,
  2: <Persona />,
  3: <LifeCycle />,
};

export default function HomeSolutionCases() {
  const [activeKey, setActiveKey] = useState('1');

  function onTabsChange(_activeKey) {
    setActiveKey(_activeKey);
    document.querySelector('.tabs-ink-bar').style.left = `${(parseInt(_activeKey, 10) - 1) * 33.3333}%`;
  }

  function renderTabBar() {
    return (
      <div className="custom-tab-bar">
        {TABS_DATA.map((tabItem) => (
          <TabTitle
            key={tabItem.bgId}
            title={tabItem.tabTitle}
            active={activeKey === tabItem.bgId}
            onMouseEnter={() => onTabsChange(tabItem.bgId)}
          />
        ))}
        <div className="tabs-ink-bar" style={{ left: 0 }} />
      </div>
    );
  }

  return (
    <section id="tags-scens-section" className="tags-scens-section">
      <div className="mask">
        <Container>
          <div className="main-title">丰富的营销应用场景</div>
          <Tabs tabPosition="top" onChange={onTabsChange} activeKey={activeKey} renderTabBar={renderTabBar}>
            {TABS_DATA.map((tabItem) => (
              <Tabs.TabPane tab={tabItem.tabTitle} key={tabItem.bgId}>
                <div className="case-content">{RENDER_MAP[activeKey]}</div>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Container>
      </div>
    </section>
  );
}