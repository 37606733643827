import React from 'react';
import { Col } from 'antd';
import Layout from '../components/Layout';
import CommonPageBanner from '../components/CommonPageBanner';
import VideoTextCard from '../components/VideoTextCard';
import HomeLogos from '../components/HomeLogos';
import Tags1 from '../images/tags-1.svg';
import ImageTextDescription from '../components/ImageTextDesctiption';
import TitledVerticalBoxes from '../components/TitledVerticalBoxes';
import VerticalBox from '../components/VerticalBox';
import TagsAdviser from '../components/TagsAdviser';
import TagsScensSection from '../components/TagsScensSection';

const DIGITAL_DATA = [
  {
    id: 'scrm-1',
    iconIndex: 2,
    title: '精准客户洞察',
    description: '利用标签细分消费者，洞察客户画像与行为特征，寻找高价值沟通触点与沟通模式',
  },
  {
    id: 'scrm-2',
    iconIndex: 79,
    title: '增强客户体验',
    description: '确保客户收到正确的感兴趣的营销内容，并可实现跨部门与客户的双向沟通和反馈',
  },
  {
    id: 'scrm-3',
    iconIndex: 80,
    title: '提高运营效率',
    description: '根据标签体系建立精准客户分层，将个性化内容的路径流程配置与节点标签记录，提高营销运营效率',
  },
  {
    id: 'scrm-4',
    iconIndex: 81,
    title: '推动价值转化',
    description: '根据客户标签的组合建模进行分析和预测，提高Cross sell/Up sell的精准度和营销效果',
  },
  {
    id: 'scrm-5',
    iconIndex: 33,
    title: '广告效果提升',
    description: '标签系统可回流精准DMP人群包，与AD Hub平台结合可实现广告的精准投放和效果监测',
  },
  {
    id: 'scrm-6',
    iconIndex: 82,
    title: '开放接口和标签库',
    description: 'Convertlab营销云具有成熟完毕的开放接口，将形成的标签库通过数据接口输出到第三方系统',
  },
];

export default () => (
  <Layout current="scens" subCurrent="tags">
    <CommonPageBanner
      title="智能化用户标签体系构建"
      description="这是一把开启数字化、智能化、自动化营销的钥匙，是营销发展史上第一次实现AI智能化商品推荐的核心环节"
      imgName="tags"
      maskColor="blue"
    />
    <VideoTextCard
      title="基于Convertlab一体化营销云的用户标签体系"
      image={Tags1}
      className="tags"
      hideApplyBtn
      imageCol={10}
      textCol={12}
    >
      <ImageTextDescription label="营销的核心和本质是真正了解自己的客户，并通过恰当的渠道和时机将品牌和商品价值持续传递给客户，这一个极为复杂的营销工程，由于流程中存在着众多变量和未知，传统营销手段已经无法精准地执行和达成效果。" />
      <ImageTextDescription label="Convertlab营销云通过建立体系化的多维度标签，将客户属性特征、客户偏好、营销内容、商品品类、沟通反馈等标签化，用智能化自动化的营销流程为数以亿计的客户群体带来美妙的个性化营销体验。用精准标签体系驱动营销运营和决策，从而将AI智能技术与营销创意、客户心智进行融合，触达营销核心本质，实现业务高速增长。" />
    </VideoTextCard>
    <TitledVerticalBoxes title="建立标签体系的营销业务价值" sectionId="tags">
      {DIGITAL_DATA.map((item) => (
        <Col lg={8} md={8} sm={12} xs={24} className="icon-text-col same" key={item.id}>
          <VerticalBox {...item} />
        </Col>
      ))}
    </TitledVerticalBoxes>
    <TagsScensSection />
    <TagsAdviser />
    <HomeLogos />
  </Layout>
);
